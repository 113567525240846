.yoga-picture{
    height: calc(100vh - 123px);
}

.yoga-content{
    min-height: calc(100vh -123px);
    background-color: #b42167;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.yoga-label-content{
    width: 50%;
}

.yoga-picture .yoga-img-content{
    display: block;
}

.yoga-img-content{
    max-height: calc(100vh -123px);
    background-color: #b42167;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
}

.yoga-img-container{
    position: relative;
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 123px);
    overflow: hidden;
}

.yoga-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 123px);
}

.yoga--main-logo{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.yoga-h2{
    padding-top: 80px;
    padding-bottom: 40px;
}

.yoga-whatIsHerznah{
    max-width: 730px;
    margin: 0 auto; 
    padding-bottom: 80px;
}

.yoga-courses{
    background-color: #b42167;
    text-align: left;
    padding: 80px;
}

.yoga-courseplan{
    padding-bottom: 80px;
}

.schwanger-head {
    background-color: #b42167;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Allow flex items to wrap to the next line */
}

.schwanger-img-container {
    padding: 80px;
    width: 50%;
    height: 500px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1; /* Allow the items to take up equal width in a row */
    max-width: 50%; /* Limit the width of each item to 50% */
}

.image-wrapper {
    width: 100%;
    height: 100%;
}

.schwanger-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

/* Media query for phone-sized screens */
@media (max-width: 768px) {
    .schwanger-head{
        display: block;
    }
    .schwanger-img-container {
        max-width: 100%; /* Allow items to take up the full width when the screen is small */
        width: auto;
    }
}


.schwanger-head-label{
    background-color: #b42167;
    padding: 20px 80px 30px 80px;
    text-align: start;
}


.schwanger-details{
    max-width: 730px;
    margin: 0 auto; 
    padding-bottom: 80px;
    padding-top: 80px;
    text-align: start;
}

.yoga-alle-details{
    max-width: 730px;
    margin: 0 auto; 
    padding-top: 80px;
    text-align: start;
}


.yoga-alle-kosten{
    background-color: #b42167;
}

.yoga-alle-kosten .yoga-alle-details p:last-of-type{
    margin-bottom: 0px;
}

.yoga-kinder-kosten{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px;
}

.yoga-kinder-content{
    padding-top:80px;
}

.yoga-kinder-background{
    background-color: #b42167;
    padding-bottom: 0px;;
}

.yoga-alle-kosten-container{
    display: flex;
    flex-wrap: wrap;
}

.yoga-kinder-center{
    justify-content: center;
}

.yoga-alle-kosten-column{
    flex-basis: 45%;
    padding: 20px;
}

.schwanger-center-p{
    text-align: center;
}
.schwanger-center-p a{
    color: black;
    margin: 0;
}

/* Solid border */
hr.solid {
    border-top: 1px solid #dddada;
  }

  .p-margin{
    margin-left: 59px;
  }
  

@media (max-width: 768px) {
    .yoga-content{
        flex-direction: column-reverse;
    }

    .yoga-img-content{
        width: 100%;
        max-height: calc(80vh);
    }

    .yoga-alle-kosten-column{
        flex-basis: 100%;
        padding: 20px;
    }
  }