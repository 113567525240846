.homepage-logo-komplett{
    width: 100%;
    object-fit: contain;
    height: calc(100vh - 123px);
}

.werbung_buecher{
    width: 100%;
    object-fit: contain;
}

.homepage-h2{
    padding-top: 80px;
    padding-bottom: 40px;
}

.hompage-whatIsHerznah{
    max-width: 730px;
    margin: 0 auto; 
    padding-bottom: 80px;
}

.neuigkeiten-section {
    min-height: calc(100vh);
    background-image: url('../assets/Logo/Logo_Hintergrund.jpeg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    isolation: isolate;
  }

  .neuigkeiten-section::after{
    content: '';
    inset: 0;
    z-index: -1;
    position: absolute;
    opacity: .6;
    background-color: white;
  }

  .neuigkeiten-content {
    z-index: 1;
    padding: 20px;
    color: black;
  }

  .neuigkeiten-content a{
    color: black;
    margin:0;
  }

  .reviews-list {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next row */
    justify-content: center; /* Adjust this as needed for horizontal spacing */
    align-items: center; /* Adjust this as needed for vertical alignment */
    /* Add any other styles you need */
  }

  .review-section-dynamic {
    background-image: url('../assets/Logo/Logo_Hintergrund.jpeg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    isolation: isolate;
  }

  .review-section-dynamic::after{
    content: '';
    inset: 0;
    z-index: -1;
    position: absolute;
    opacity: .8;
    background-color: white;
  }

  .review-section-dynamic h3{
    align-self: flex-start;
    margin-left: 10px;
  }
 