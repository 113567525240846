.coaching-picture {
    display: flex;
    align-items: center;
    height: 160vh;
    max-width: 100%;
  }

  /* Style for the text content */
  .coaching-content {
    width: 50%;
    text-align: center;
    padding: 20px;
  }

  /* Style for the image container */
  .coaching-img-content {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f0f0f0; 
  }

  /* Style for the image itself */
  .coaching-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  /* Media query for small screens */
  @media screen and (max-width: 768px) {
    .coaching-picture {
      display: flex;
      align-items: center;
      height: 260vh;
      max-width: 100%;
    }

    .coaching-img-content {
      width: 100%;
    }

    .coaching-content {
      width: 100%;
    }
  }

.coaching-main-logo{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.shop-iframe{
    width: 100%;
    height: 100%;
    border: none;
}

