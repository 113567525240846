.card-list-content {
    display: flex;
    flex-wrap: wrap; /* Allow cards to wrap to the next row when needed */
    justify-content: space-between; /* Distribute cards evenly along the row */
  }
  
  .card-content {
    width: 266px; /* Adjust the width as needed */
    margin: 10px; /* Add margin to create space between cards */
    padding: 10px;
    text-align: center; /* Center content within each card */
    box-sizing: border-box; /* Include padding and border in the width calculation */
  }

  .card-image-content {
    /* Other styles for your card content */
    width: 266px; /* Set a fixed width for the card */
    height: 266px; /* Set a fixed height for the card */
    overflow: hidden; /* Hide any overflow content */
    position: relative; /* Enable absolute positioning for the image */
  }
  
  .card-image {
    width: 100%; /* Ensure the image takes up the entire card width */
    height: 100%; /* Ensure the image takes up the entire card height */
    object-fit: cover; /* Maintain aspect ratio and cover the entire area */
    object-position: center center; /* Center the image within the card */
    position: absolute; /* Position the image absolutely within the card */
  }
  
  .card-header-label {
    margin-top: 10px; /* Add space between the image and h3 element */
  }
  
  .card-main-label {
    margin-top: 10px; /* Add space between the h3 and p elements */
  }

  .card-button {
    margin-top: auto;
    display: inline-block;
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: white;
    color: black;
    text-decoration: none; /* Remove underlines for links */
    border-radius: 5px; /* Adjust the border-radius for rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease; /* Add a smooth transition effect */
  
    /* Center the text horizontally and vertically within the button */
    text-align: center;
    line-height: 1.5;
  }
  
  .card-button:hover {
    background-color: lightgrey;
  }
  