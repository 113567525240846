.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navigation{
  flex: 0;
}

.content{
  flex: 1;
}

.footer{
  padding: 60px 0px;
  background-color: white;
}

.pacifico{
  font-family: pacifico, cursive;
  font-weight: 400;
}

p{
  line-height: 1.6;
}

strong{
  line-height: 1.6;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #03b2cb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.img-jasmin{
  max-height: 500px;
}

.section{
  max-width: 850px;
  margin: 0 auto;
  text-align: left;
}

.rounded-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  border-radius: 5px; /* Adjust the value for your desired level of rounding */
  padding: 10px;
  color: white;
  margin: 50px 0px;
}

/* Style the text on the left */
.reounded-row p {
  flex: 1; /* Allow the text to grow and take up available space */
  padding-left: 10px; /* Add some left padding for spacing */
}

/* Style the button on the right */
.rounded-row button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px; /* Adjust the value for button rounding */
  padding: 10px 20px;
  cursor: pointer;
}

.rounded-row a {
  margin-top: auto;
  display: inline-block;
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: white;
  color: black;
  text-decoration: none; /* Remove underlines for links */
  border-radius: 5px; /* Adjust the border-radius for rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Add a smooth transition effect */

  /* Center the text horizontally and vertically within the button */
  text-align: center;
  line-height: 1.5;
}

.rounded-row a:hover {
  background-color: lightgrey;
}

.googlelink{
  color: #03b2cb;
}


.impressum{
  padding: 50px;
}

@media screen and (max-width: 768px) {
  .img-jasmin{
    height: 100% ;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
