.footer{
    float: left;
    width: 100%;
}

.footer.yoga {
    background-color: #b42167;
}
  
.footer.geburtsvorbereitung {
background-color: #fcd120;
}

.footer.coaching {
background-color: #007ee7 ;
}

a{
    color: black;
    margin: 10px;
    text-decoration: underline;
}