.gbv-picture {
    display: flex;
    align-items: center;
    height: calc(100vh - 123px);
    max-width: 100%;
  }

  /* Style for the text content */
  .gbv-content {
    width: 50%;
    text-align: center;
    padding: 60px;
  }

  /* Style for the image container */
  .gbv-img-content {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 123px);
    background-color: #f0f0f0; 
  }

  /* Style for the image itself */
  .gbv-img {
    max-width: 100%;
    max-height: calc(100vh - 123px);
    object-fit: cover;
  }

  /* Media query for small screens */
  @media screen and (max-width: 768px) {
    .gbv-picture {
      flex-direction: column;
      height: auto;
    }

    .gbv-img-content {
      width: 100%;
    }

    .gbv-content {
      padding: 60px;
      width: 100%;
    }
  }

.gbv-main-logo{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.gbv-explain{
    max-width: 730px;
    margin: 0 auto; 
    padding: 60px 20px;
    text-align: start;
}

.gbv-explain-2 {
    text-align: left; /* Reset text alignment for the inner content */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    padding-top:80px;
  }

  .gbv-list {
    padding-top:15px;
    max-width: 850px; /* Set a maximum width for the list */
    width: 100%; /* Ensure the list takes up the full width within the maximum */
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items horizontally */
  }

  .gbv-listitem {
    flex: 0 0 calc(20% - 10px); /* 20% width with some margin between items */
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column; /* Make each list item a flex container */
    align-items: center;
  }

  .gbv-listitem p {
    padding-bottom: 30px;
  }

  .gbv-explain-3 {
    text-align: left; /* Reset text alignment for the inner content */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    margin: 20px 80px;
  }


  .gbv-p{
    margin: 0;
  }

  .listitem-center{
    text-align: center;
  }

  .gbv-card-button {
    margin-top: auto;
    display: inline-block;
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: black;
    color: white;
    text-decoration: none; /* Remove underlines for links */
    border-radius: 5px; /* Adjust the border-radius for rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease; /* Add a smooth transition effect */
  
    /* Center the text horizontally and vertically within the button */
    text-align: center;
    line-height: 1.5;
  }

  .gbv-card-button:hover{
    background-color: rgba(0, 0, 0, .6);
  }

  .gbv-section {
    min-height: calc(100vh);
    background-image: url('../../assets/Frauen\ gelber\ Hintergrund/AdobeStock_156229979.jpeg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    isolation: isolate;
  }

  .gbv-section::after{
    content: '';
    inset: 0;
    z-index: -1;
    position: absolute;
    opacity: .8;
    background-color: white;
  }

  .gbv-content a{
    color: black;
    margin:0;
  }

  .gbv-section-dynamic {
    background-image: url('../../assets/Frauen\ gelber\ Hintergrund/AdobeStock_156229979.jpeg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    isolation: isolate;
  }

  .gbv-section-dynamic::after{
    content: '';
    inset: 0;
    z-index: -1;
    position: absolute;
    opacity: .8;
    background-color: white;
  }

  .gbv-content-dynamic {
    z-index: 1;
    padding: 20px;
    color: black;
  }

  .gbv-content-dynamic a{
    color: black;
    margin:0;
  }

  .gbv-text-left{
    text-align: start;
  }

  .gbv-text-center{
    text-align: center;
  }

  .gbv-padding-100{
    padding: 100px 20px;
  }

  .gbv-image-text-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .gbv-image-text-item{
    width: calc(50% - 20px); /* 50% width for each item with some spacing */
    margin: 10px;
    text-align: center;
  }

  .gbv-image{
    max-width: 100%;
    height: auto;
  }

  .gbv-text{
    max-width: 500px;
    padding: 5px;
    margin: 0 auto;
    text-align: left;
  }


  
  
  @media (max-width: 768px) {
    .gbv-listitem {
      flex-basis: 95%; /* Full width on smaller screens */
    }

    .gbv-image-text-item{
      width: 100%;
    }
  }