body {
  margin: 0;
  font-family: 'MooliRegular', 'Pacifico', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'MooliRegular';
  src: local('MooliRegular'), url('./fonts/Mooli-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Pacifico';
  src: local('Pacifico'), url('./fonts/Pacifico-Regular.ttf') format('truetype');
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
