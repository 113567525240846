/* NavigationBar.css */

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: white;
    color: black;
  }

  .navigation.yoga {
    background-color: #b42167;
  }
  
  .navigation.geburtsvorbereitung {
    background-color: #fcd120;
  }

  .navigation.coaching {
    background-color: #007ee7;
  }
  
  .logo {
    font-size: 1.5rem;
  }
  
  .menu {
    list-style: none;
    display: flex;
  }
  
  .menu li {
    margin: 20px 10px;
  }
  
  .menu a {
    color: black;
    text-decoration: none;
  }

  .menu a:hover {
    text-decoration: underline;
  }

  .menu .dropdown-content a{
    color: black !important;
  }

  .menu .dropdown-content a:hover{
    color: black !important;
    text-decoration: underline;
  }
  
  .dropdown-content {
    padding: 5px 10px;
    display: none;
    position: absolute;
    background-color: white;
    border-radius: 5px;
    border: 1px solid black;
    list-style: none;
    padding: 0.5rem;
    z-index: 1;
  }

  
  
  .dropdown:hover .dropdown-content {
    display: block;
    color: black;
  }
  
  .burger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 5px;
    background-color: #03b2cb;
    border: none;
    color: white;
  }
  
  .burger-menu.active {
    background-color: transparent;
  }
  
  .bar {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
  }

  #nav-logo{
    width: 60px;
    height: 60px;
  }
  
  @media (max-width: 768px) {
    .menu {
      display: none;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  
    .menu.active {
      display: flex;
    }
  
    .burger-menu {
      display: flex;
    }

    
  }
  